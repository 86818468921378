import axios from "axios";
import { useState } from "react";

import { DynamicForm } from "@/components/DynamicForm";
import { TODO } from "@/types";

// import { usePageData } from "@/hooks/usePageData";

type ForgotPasswordValidationError = {
  email: string[];
};

type ForgotPasswordResponse = {
  errors: ForgotPasswordValidationError | [];
  message: string | boolean;
};

export default function ForgotPassword({ uri }: { uri?: string }) {
  console.log(uri);
  const [emailSent, setEmailSent] = useState(false);

  const submit = async (values: TODO) => {
    const res = await axios.post<ForgotPasswordResponse>(
      route("api.auth.forgot"),
      { email: values.email, uri: uri },
    );

    if (res.status === 201) {
      setEmailSent(true);
    }
  };

  return (
    <>
      {uri && (
        <div className="absolute top-0 right-0 mt-10 mr-8">
          <span className="mr-2 text-sm text-body-text-default">
            Remember your password?
          </span>
          <a
            href={atob(uri)}
            className="text-button-primary-default-bg text-sm"
          >
            Sign in
          </a>
        </div>
      )}
      <div className="">
        <h1 className="text-4xl font-normal text-body-text-default inline-flex items-center pb-4">
          Reset your password
        </h1>
        {!emailSent ? (
          <DynamicForm
            submitButtonText={"Email link"}
            submitButtonStyle="w-full"
            formInfo={[
              {
                fields: [
                  {
                    name: "email",
                    label: "Work email",
                    accessorKey: "email",
                    type: { input: "TEXT" },
                    validation: { required: "Please enter your email" },
                  },
                ],
              },
            ]}
            initialValues={{}}
            onSubmit={submit}
          />
        ) : (
          <div className="text-body-text-default text-sm space-y-4">
            <p>
              If the email address you provided is associated with an account,
              you will receive an email with a link to reset your password.
            </p>
            <p>
              If you don{"'"}t receive an email, please make sure you{"'"}ve
              entered the address you registered with, check your spam folder or{" "}
              <br />
              <a
                href="mailto:support@octfolio.com"
                className="text-sm text-button-primary-default-bg"
              >
                contact support
              </a>
              .
            </p>
          </div>
        )}
      </div>
    </>
  );
}
